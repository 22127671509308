.gnt-dropdown-section {
    width: 95%;
    border: var(--brGeneral) solid;
    border-width: 0 0 1px;
    padding: 0.2rem 0.5rem;
    margin: 1rem 0 2rem;
}

.gnt-dropdown-section select {
    width: 100%;
    padding: .5rem .3rem;
    border: none;
    background: none;
    height: auto;
    font-size: 1.2rem;
    user-select: none;
    outline: none;
}