.gnt-mapGoogle-title {
    padding: 1rem 0;
    position: absolute;
    top: 0;
    height: 10%;
    width: 100%;
    background: #FFF;
}

.gnt-mapGoogle-address {
    padding: 1.5rem 0 0;
    width: 100%;
    margin: 0 auto;
    background: #FFF;
}

.gnt-mapGoogle-address input {
    width: 75%;
    padding: .5rem;
    border: none;
    background: #ededee;
    font-size: .9rem;
}

.gnt-mapGoogle-address button {
    border-radius: 0.3rem;
    color: #fff;
    font-size: .9rem;
    border: none;
    padding: 0 1rem;
    background: #80bb1a;
}

.gnt-mapGoogle-content {
    position: absolute;
    width: 100%;
    height: 67%;
    top: 18%;
    display: block;
}

.gnt-mapGoogle-buttons {
    position: absolute;
    width: 100%;
    height: 10%;
    top: 85%;
    background: #FFF;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 99999;
    background: #FFF;
    width: 74%;
    left: 50%;
    margin-left: -37%;
    text-align: left;
    padding: 0 1rem;
    font-size: .9rem;
}

.suggestion-item {
    padding: .5rem;
}


.gnt-mapGoogle-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: relative;
    display: flex;
}

i.iconGeo {
    width: 6px !important;
    height: 6px !important;
    margin: -3px;
    display: flex;
    left: 50%;
    top: 50%;
    position: absolute;
    z-index: 999999;
}

i.iconGeo svg {
    width: 48px;
    height: 48px;
    position: absolute;
    left: -21px;
    top: -44px;
}

i.iconGeo svg path {
    fill: var(--svgFill);
}

#cntGoogleAddress {
    display: flex;
    justify-content: space-around;
}

#cntGoogleAddress div {
    width: 60%;
}

.changeAddress {
    position: absolute;
    top: 0.8rem;
    left: 50%;
    width: 87%;
    height: 86vh;
    background: #FFF;
    z-index: 9999999;
    margin-left: -47.5%;
    padding: 2rem 1rem;
    text-align: center;
    display: none;
    align-items: center;
}

.changeAddressContent {
    width: 100%;
}

.chaInput {
    width: 85%;
    margin: 2rem auto;
    text-align: left;
}

.chaInput input {
    width: 93%;
    padding: 0.5rem;
    font-size: 1rem;
}

.mai-geolocation {
    width: 90%;
    height: auto;
    text-align: center;
    padding: 0 1rem;
    margin: 0 auto;
}

.pac-target-input {
    margin-right: 1rem;
}

.btnClose {
    position: absolute;
    top: 0;
    right: 9px;
    z-index: 999999999999999999999;
    font-size: 1.2rem;
    padding: 0.5rem 0.8rem;
    border-radius: 50%;
}

.mai-description {
    margin: 1rem 0;
}