.imgImage {
	position: relative;
	border: none;
	margin: 1rem 0;
}

.imgImage img {
	max-width: 100%;
	max-height: 180px;
	position: relative;
}

.gnt-image-preview {
	position: relative;
	border: none;
	display: flex;
	margin: 0 auto 1rem;
}

.gnt-image-preview img {
	max-width: 100%;
	position: relative;
}

.imgIcon {
	position: relative;
	border: none;
	margin: 0 0 1rem;
}

.imgIcon img {
	max-width: 40%;
	position: relative;
}

.imgIcon img#idIcon {
	max-width: 80%;
	position: relative;
}

.imgIcon img path {
	fill: blue;
}


/* .imgImage-svg {
	position: relative;
	border: none;
}

.imgImage-svg img {
	max-width: 60%;
	position: relative;
}

.imgImage svg {
	margin: 0 auto 1rem !important;
	max-width: 81%;
	height: 200px;
}

.gnt-image-preview svg {
	margin: 0 auto 1rem !important;
} 

@media screen and (min-width: 375px) {
	.imgImage svg {
		margin: 0 auto 1rem !important;
	}
}

@media screen and (min-width: 560px) {
	
}*/