.gnt-front-preview {
    width: 90%;
    height: auto;
    max-height: 100vh;
    text-align: center;
    margin: 0 auto;
}

#fntPicPrev {
    display: none;
}

#fntPicPrevMsk {
    display: block;
}

.gnt-front-preview img {
    width: 100%;
    margin: 0 auto;
    max-height: 25rem;
}

.gnt-buttons-container {
    display: flex;
    align-items: center;
    max-width: 20rem;
    margin: 0 auto;
    justify-content: space-around;
}

.gnt-front-preview-image {
    width: 90%;
    min-height: 170px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 2rem auto;
    padding: 1rem;
    background: #F1F1F1;
}

.gnt-front-picture p {
    color: var(--colorDescription);
}

.gnt-front-preview p {
    color: var(--colorDescription);
}

.gnt-buttons-container {
    margin-top: 1rem;
}

#white-flash.normal {
    display: none !important;
}