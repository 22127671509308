.gnt-mi-elements {
    margin: 2rem 0;
}

.mi-element-vl {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.mi-element-inp {
    width: 40%;
}

.mi-element-inp input {
    max-width: 87%;
    border: none;
    border-bottom: 1px #CCC solid;
    padding: 0.2rem 0.5rem;
    font-size: .8rem;
    color: var(--colorText)
}

.mi-element-txt {
    padding: 0.5rem;
    width: 60%;
}


.mi-element-txt p {
    font-size: .7rem;
    margin: 0 !important;
}

.mi-element-tl {
    display: flex;
    align-items: center;
}

.mi-element-tl div.mi-element-inp input {
    max-width: 100%;
    border: none;
    padding: 0.2rem 0.5rem;
    font-size: .8rem;
}