.cmDatePicker button {
    width: 96%;
    padding: .7rem.3rem;
    text-align: center;
    font-size: 1.1rem;
    border: 1px #CCC solid;
    background-color: #FFF;
    border-radius: .5rem;
    margin: 1rem 0;
}

.mai-birthday-age {
    text-align: center;
    padding: 1rem 0;
}

.datepicker-modal {
    z-index: 9999;
}

.datepicker .datepicker-scroll>li {
    margin: 0;
}

.datepicker .datepicker-navbar-btn {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--buttonBackground) !important;
}

.datepicker.ios .datepicker-header {
    color: var(--buttonBackground);
    padding: 0 5.5em;
    font-size: 1.2rem;
    margin-top: 0.3rem;
    font-weight: bold;
}