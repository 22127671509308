.sigContent {
    display: block;
    align-items: center;
    width: 90%;
    margin: 2rem auto;
}

.sigElement {
    width: 100%;
    position: relative;
}

.sigCanvas {
    width: 99%;
    height: 187px;
    border: 1px #CCC solid;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    background: #FFF;
}

.sigButtons {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.sigButtons button {
    font-size: 1rem;
    padding: 0.5rem 0;
    width: 48%;
    margin: 0.5rem 0;
    border: 1px var(--brGeneral) solid;
    background: var(--bkButtonSuccess);
    color: var(--txButtonSuccess);
}

@media screen and (min-width: 560px) and (orientation:landscape) {
    .sigContent {
        display: flex;
        align-items: center;
        width: 90%;
        margin: 0 auto;
    }

    .sigElement {
        width: 85%;
    }

    .sigCanvas {
        width: 543px;
    }

    .sigButtons {
        width: 15%;
        margin-top: 0;
    }
}