#cnvCamMaskSelfie {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: url(../images/selfieMask.png) center no-repeat;
    background-size: contain;
}

#outer-circle {
    left: 0;
}

.camSections {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 95;
}

.camTitleSelf {
    height: 15vh !important;
    text-align: center;
    padding-top: 10vh;
}

.camTitleSelf h2 {
    color: #FFF !important;
}

.camTitleSelf p {
    color: #FFF !important;
}

.camPhotoSelf {
    height: 53vh !important;
    text-align: center;
    background: url(../images/selfieMask.png) center !important;
}

.camInfoSelf {
    height: 12vh !important;
}

.camInfoSelf ul {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.camInfoSelf ul li {
    color: #FFF;
    font-size: .9rem;
    margin: 0;
    padding: .2rem 0;
}

.guide__cross {
    background-color: initial;
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    z-index: 3;
    width: 1rem;
}

.guide__cross svg path {
    fill: white;
    cursor: pointer;
}

.cam-title {
    font-size: 1.6rem;
    font-weight: 600;
    position: fixed;
    top: 4rem;
    width: 100%;
    text-align: center;
    color: #FFF;
    z-index: 9999;
}

.cam-description {
    position: absolute;
    width: 80%;
    margin: 0 0 0 -40% !important;
    top: 6rem;
    left: 50%;
    text-align: center;
    color: #FFF;
}

.canvas__top {
    background-color: rgba(0, 0, 0, .588);
    width: 100vw;
    height: 15vh;
}

.canvas__bottom {
    background-color: rgba(0, 0, 0);
    color: #fff;
    font-family: sans-serif;
    text-align: center;
    width: 100vw;
    height: -webkit-fill-available;
    z-index: 1;
    font-size: 1.4rem;
    position: absolute;
    top: 65vh;
}

.canvas__bottom--texthelp {
    list-style: none;
    padding: 0;
    width: 60%;
    text-align: left;
    margin: 0 auto;
}

.canvas__bottom--texthelp li {
    font-size: .9rem;
    color: #FFF;
}

.canvas_buttons {
    position: absolute;
    width: 2.5rem;
    z-index: 9999;
    bottom: 4.5rem;
    right: 4rem;
}

.canvas_buttons svg path {
    fill: #FFF;
}

.camSelfiMask {
    background-color: #1e1d1d;
    position: absolute;
    top: 0;
    left: 0;
}

.box {
    width: 100%;
    height: 100vh;
    position: relative;
    box-shadow: inset 0 0 0 10px #fbfcf7, inset 0 0 0 11px #d3d0c9;
    -ms-touch-action: none;
    touch-action: none;
}

.camInfo p {
    width: 80%;
    margin: 0 auto !important;
    text-align: center;
    color: #FFF;
}

.cam-selfie div.camTitle {
    height: 5vh !important;
}

.clSelfie {
    width: 320px;
    height: 320px;
    text-align: center;
    background: none;
    position: relative;
    margin: 2rem auto;
    padding: 0;
}

.clSelfie div.camMaskItem {
    position: absolute;
    background: url(../images/maskSelfie.png) center no-repeat;
    background-size: 100%;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.clSelfie div#container-circles {
    position: absolute;
    left: 50%;
    bottom: -12rem !important;
    height: 5rem;
    width: 5rem;
    margin-left: -2.5rem;
    z-index: 99;
}

.clSelfie div.react-html5-camera-photo>.display-error,
.clSelfie div.react-html5-camera-photo>img,
.clSelfie div.react-html5-camera-photo>video {
    width: 316px !important;
    height: 318px !important;
    margin: 0 auto;
}

.clSelfie div.react-html5-camera-photo>img {
    display: none !important;
}