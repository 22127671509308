/* .element[data-type=is] {
    width: 100%;
    padding: 0 !important;
} */

.camMask {
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100vw;
    overflow: hidden;
    background-color: #000;
}

#cnvCamMask {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    ;
    z-index: 9;
}

#container-circles {
    position: fixed;
    left: 50%;
    bottom: 2rem;
    height: 5rem;
    width: 5rem;
    margin-left: -2rem;
    z-index: 99;
}

#outer-circle {
    left: 0;
}

.camSections {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 99;
}

.camTitle {
    text-align: center;
    padding-top: 7vh;
    background: none;
}

.camTitle h2 {
    color: #FFF !important;
}

.camTitle p {
    color: #FFF !important;
}

.camInfo {
    background: none;
    text-align: left;
    width: 80%;
    margin: 0 auto;
}

.camInfo ul {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

.camInfo ul li {
    color: #FFF;
    font-size: .9rem;
    list-style: square;
    margin: 1rem 0;
}

.guide__cross {
    background-color: initial;
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    z-index: 3;
    width: 1rem;
}

.guide__cross svg path {
    fill: white;
    cursor: pointer;
}

.cam-title {
    font-size: 1.6rem;
    font-weight: 600;
    position: fixed;
    top: 4rem;
    width: 100%;
    text-align: center;
    color: #FFF;
    z-index: 9999;
}

.cam-description {
    position: absolute;
    width: 80%;
    margin: 0 0 0 -40% !important;
    top: 6rem;
    left: 50%;
    text-align: center;
    color: #FFF;
}

.canvas__top {
    background-color: rgba(0, 0, 0);
    width: 100vw;
    height: 20vh;
}

.canvas__bottom {
    background-color: rgba(0, 0, 0);
    color: #fff;
    font-family: sans-serif;
    text-align: center;
    width: 100vw;
    height: -webkit-fill-available;
    z-index: 1;
    font-size: 1.4rem;
    position: absolute;
    top: 65vh;
}

.canvas__bottom--texthelp {
    list-style: none;
    padding: 0;
    width: 60%;
    text-align: left;
    margin: 0 auto;
}

.canvas__bottom--texthelp li {
    font-size: .9rem;
    color: #FFF;
}

.canvas_buttons {
    position: absolute;
    width: 2.5rem;
    z-index: 9999;
    bottom: 4.5rem;
    right: 4rem;
}

.canvas_buttons svg path {
    fill: #FFF;
}

.box {
    width: 100%;
    height: 100vh;
    position: relative;
    box-shadow: inset 0 0 0 10px #fbfcf7, inset 0 0 0 11px #d3d0c9;
    -ms-touch-action: none;
    touch-action: none;
}

.clIDDocument {
    text-align: center;
    background: none;
    position: relative;
    width: 95%;
    height: 254px;
    margin: 3rem auto 0;
    padding: 0;
    overflow: hidden;
}

.clPassport {
    text-align: center;
    background: none;
    position: relative;
    width: 95%;
    height: 295px;
    margin: 3rem auto 0;
    padding: 0;
    overflow: hidden;
}

.clIDDocument div.camMaskItem {
    position: absolute;
    background: url(../images/maskId.png) center no-repeat;
    background-size: 100%;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.clPassport div.camMaskItem {
    position: absolute;
    background: url(../images/maskPassport.png) center no-repeat;
    background-size: 100%;
    z-index: 99;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}


.clIDDocument div.react-html5-camera-photo {
    text-align: center;
    width: 95%;
    height: auto;
    margin: 0 auto;
    top: 0;
}

.clPassport div.react-html5-camera-photo {
    text-align: center;
    width: 95%;
    height: auto;
    margin: 0 auto;
    top: 0;
}

.clIDDocument div.react-html5-camera-photo>.display-error,
.clIDDocument div.react-html5-camera-photo>img,
.clIDDocument div.react-html5-camera-photo>video {
    margin-top: 5px;
}

.clPassport div.react-html5-camera-photo>.display-error,
.clPassport div.react-html5-camera-photo>img,
.clPassport div.react-html5-camera-photo>video {
    margin-top: 5px;
}


.clIDDocument div.react-html5-camera-photo>img {
    display: none !important;
}

.clPassport div.react-html5-camera-photo>img {
    display: none !important;
}

.react-html5-camera-photo {
    width: 100%;
    height: 100%;
}

.react-html5-camera-photo-fullscreen>img,
.react-html5-camera-photo-fullscreen>video {
    width: 90vw;
    height: auto !important;
}

@media screen and (min-width: 430px) {
    .clIDDocument {
        height: 264px;
    }
}