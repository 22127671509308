.gnt-forms-short {
	list-style: none;
	text-align: left;
	display: block;
	align-items: center;
	margin: 0 0 0.5rem;
	padding: 0;
}

.gnt-forms-short li {
	padding: 0.5rem;
	border: 1px solid var(--brGeneral);
	background: var(--bkMultipleChoice);
	width: 11rem;
	margin: 0.5rem 0;
	cursor: pointer;
	border-radius: 0.2rem;
}

.gnt-forms-short li svg {
	width: 3rem;
	height: 3rem;
	margin-bottom: 0.5rem;
}

.gnt-legal-item {
	display: flex;
	align-items: center;
}

.gnt-legal-item svg {
	width: 1rem !important;
	height: auto !important;
	margin: 0 !important;
}

.gnt-legal-item svg path {
	fill: var(--svgFill);
}

.chid {
	padding: 0.2rem 0.6rem;
	margin-right: 0.5rem;
	border-radius: 0.2rem;
	border: 1px solid;
	border-color: var(--brGeneral);
	background: var(--bkMultipleChoiceNumber);
	color: var(--txMultipleChoiceNumber);
	font-size: .9rem;
}

.chtx {
	width: 75%;
	position: relative;
	color: var(--txMultipleChoice);
	font-size: 1rem;
}

.chtx i {
	position: absolute;
	right: 0;
	padding: 0 0.2rem;
	display: none;
}

.chck {
	display: none;
}

#terms {
	display: none;
}

#terms p {
	text-align: left;
	font-size: 0.7rem !important;
	color: var(--colorError);
	line-height: 0.9rem !important;
	padding-top: 1rem;
}

#terms button {
	font-size: .9rem;
}

div[show-error="on"] {
	display: block;
}

div[show-error="off"] {
	display: none;
}