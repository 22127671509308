div[data-show="off"] {
	display: none;
}

.card {
	width: 90%;
	max-width: 275px;
	margin: 1rem auto;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	border: 1px var(--brGeneral) solid;
}

.card-image {
	height: 10rem;
	display: flex;
	text-align: center;
	position: relative;
	align-items: center;
}

.card-image svg {
	width: 20%;
	margin: 0 auto;
}

.card-image svg path {
	fill: #888888 !important;
}

.card-image img {
	max-width: 99%;
	max-height: 98%;
	top: 0;
	left: 0;
	margin: 0 auto;
}

.card-body {
	padding: 0.8rem 1rem !important;
}

.card-body h5 {
	margin: 0 !important;
}

.card-title {
	font-size: 1rem;
	margin: 0;
	display: flex;
	align-items: center;
}

.card-title span {
	padding: 0.4rem 0.5rem 0.3rem;
	background: var(--choiceBackground);
	color: var(--choiceBorder);
	font-size: 0.8rem;
	margin-right: 0.3rem;
	border: 1px var(--brGeneral) solid;
	line-height: .6rem;
}

.gnt-views-picture-choice {
	text-align: center;
}

.picture-choice-button {
	margin: 0 0.5rem;
}

.picture-choice-button button {
	min-width: 12.5rem;
	padding: 0.5rem;
	font-size: 1.25rem;
}

.card-check {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.card-check-mask {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	right: 0;
	border-right: 0px solid transparent;
	border-left: 50px solid transparent;
	border-top: 50px solid var(--bkButtonSuccess);
	border-bottom: 0px solid var(--bkButtonSuccess);
}

.card-check svg {
	width: 50%;
	position: absolute;
	top: 0.4rem;
	left: 0.5rem;
	z-index: 99;
}

.card-check svg path {
	fill: #FFF;
}

.gnt-views-element {
	width: 100%;
}

#qstpc {
	align-items: start;
	padding: 3rem 0 10rem !important;
	height: auto;
}

#qstpc div.gnt-image-section img {
	max-width: 80%;
	position: relative;
}

@media screen and (min-width: 966px) {
	.gnt-views-picture-choice {
		text-align: left;
	}

	.card {
		margin: 1rem 0.5rem;
		display: inline-flex !important;
	}

	.card-check-mask {
		border-left: 70px solid transparent;
		border-top: 60px solid var(--buttonBackground);
	}
}