.txtTitle h1 {
    font-size: 1.2rem;
    line-height: 1.4rem;
    display: flex;
    align-items: flex-start;
    margin: 0;
    user-select: none;
    color: var(--txTitle);
}

.txtTitle h1 span.tlBullet {
    color: var(--txTitleNumber);
}

.txtTitle h1 span.tlText {
    letter-spacing: .04rem;
    color: var(--txTitle);
    width: 100%;
}

.txNum_0 {
    padding-right: 0;
}

.txNum_1 {
    padding-right: .5rem;
}