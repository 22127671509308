.gnt-input-section {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 1rem 0;
}

.gnt-input-section input {
	width: 94%;
	border: none;
	background: none;
	outline: none;
	color: var(--colorTitle);
	font-size: 1.2rem;
	border: 1px var(--brGeneral) solid;
	border-radius: 5px;
	padding: .5rem;
}

.gnt-input-section input::placeholder {
	color: #CCC;
}


.gnt-views-inputs-choice input {
	width: 100%;
	padding: .5rem 1rem;
	border: none;
	border-bottom: 1px #CCC solid;
	margin-bottom: .5rem;
}

.gnt-views-inputs-choice input::placeholder {
	color: #999;
}

.gnt-textarea-section {
	margin-top: 1rem;
}

.gnt-textarea-section textarea {
	width: 95%;
	height: 10rem;
	border: none;
	background: #f1f1f1;
	outline: none;
	color: var(--colorText);
	border-bottom: 1px #CCC solid;
	padding: .5rem;
	font-size: 1rem;
}

.gnt-textarea-section textarea::placeholder {
	color: var(--colorPlaceholder);
}

#gntInputDate {
	margin: 0 auto;
}

#gntInputDate input {
	margin: 1rem 0;
}

#age {
	font-size: 1.5rem;
	text-align: center;
	margin: 1rem 0 2rem;
}

#br input {
	text-align: center;
}

#ph .gnt-input-section {
	text-align: left;
	width: 100%;
	padding: 0.5rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
}

#gntAreaCode {
	width: 33%;
	margin-right: 2%;
}

#gntAreaCode ul {
	width: 310px;
}

#gntAreaCode input {
	font-size: 1rem !important;
	width: 92% !important;
}

#ph .gnt-input-section input {
	width: 61%;
	border: 1px #CCC solid;
	background: none;
	outline: none;
	color: var(--colorTitle);
	font-size: 1.2rem;
	padding: 0.5rem .5rem .4rem;
	border-radius: 3px;
}

.areaCode button {
	width: 90% !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
	padding-bottom: 0 !important;
}

.ReactFlagsSelect-module_secondaryLabel__37t1D {
	font-size: 1.1rem !important;
}

.gntPhone {
	text-align: left;
	width: 100%;
	padding: 0;
	margin: 1rem 0;
	display: flex;
	justify-content: space-between;
}

.gntPhone input {
	width: 56%;
	background: none;
	outline: none;
	color: var(--colorTitle);
	font-size: 1.2rem;
	border: 1px var(--brGeneral) solid;
	border-radius: 5px;
	padding: 0.5rem;
}