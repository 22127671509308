.btnButtons {
	padding: 0.5rem 0.1rem;
}

.btnButtons button {
	min-width: 8rem;
	padding: .8rem 0.5rem;
	font-size: 1.2rem;
	letter-spacing: .9px;
	line-height: 1.2rem;
	border: 2px solid;
	user-select: none;
	border-radius: .25rem;
}

.btnButtons label {
	min-width: 8rem;
	padding: .8rem 0.5rem;
	font-size: 1.2rem;
	letter-spacing: .9px;
	line-height: 1.2rem;
	border: 2px solid;
	user-select: none;
	border-radius: .25rem;
}

.cam label {
	display: block;
	width: max-content;
	margin: 0 auto;
}

.btnSuccess {
	background-color: var(--bkButtonSuccess) !important;
	border-color: var(--brButtonSuccess) !important;
	color: var(--txButtonSuccess);
}

.btnSuccess:hover {
	background-color: var(--bkButtonSuccessHover) !important;
	border-color: var(--brButtonSuccessHover) !important;
	color: var(--txButtonSuccess);
}

.btnSuccess:active {
	background-color: var(--bkButtonSuccessClick) !important;
	border-color: var(--brButtonSuccessClick) !important;
	color: var(--txButtonSuccessClick) !important;
}

.btnSuccess:focus {
	background-color: var(--bkButtonSuccessClick) !important;
	border-color: var(--brButtonSuccessClick) !important;
	color: var(--txButtonSuccessClick) !important;
}

.btnSuccess.disabled,
.btnSuccess:disabled {
	color: var(--buttonColorDisabled) !important;
	background-color: var(--buttonDisabled) !important;
	border-color: var(--buttonDisabled) !important;
}

.btnInfo {
	background: var(--bkButtonInfo) !important;
	border-color: var(--bkButtonInfo) !important;
	color: var(--txButtonInfo);
}

.btnInfo:active {
	background-color: var(--bkButtonInfoClick) !important;
	border-color: var(--brButtonInfoClick) !important;
	color: var(--txButtonInfoClick);
}

.btnInfo:focus {
	background-color: var(--bkButtonInfoClick) !important;
	border-color: var(--brButtonInfoClick) !important;
	color: var(--txButtonInfoClick);
}

.btnInfo.disabled,
.btnInfo:disabled {
	color: var(--buttonColorDisabled) !important;
	background-color: var(--buttonDisabled) !important;
	border-color: var(--buttonDisabled) !important;
}

#wpElement[data-type=tk] .btnButtons {
	margin-top: 1rem;
}


/* REVISAR */

/* .cam label {
	margin: 0 auto;
	display: block;
	padding: .5rem;
	cursor: pointer;
	width: 9.5rem;
	font-size: 1.2rem;
	text-align: center;
	letter-spacing: .9px;
} */

.cam input {
	display: none;
}

.btn-nav {
	background: var(--bkButtonSuccess) !important;
	border-color: var(--brButtonSuccess) !important;
	color: var(--txButtonSuccess);
	min-width: 0;
	height: auto !important;
	padding: 0.4rem 0.5rem 0.3rem !important;
	font-size: 1.2rem;
	letter-spacing: .9px;
	line-height: 1.2rem;
	border: 1px solid;
	cursor: pointer;
	margin: 0 1px;
}

.btn-nav:hover {
	color: var(--txButtonSuccess);
	background-color: var(--bkButtonSuccess) !important;
	border-color: var(--brButtonSuccess) !important;
}