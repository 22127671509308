.multiplechoice ul {
	margin: 1rem 0 0;
	padding: 0;
	list-style: none;
}

.multiplechoice ul li {
	margin: 0.7rem 0 0;
	padding: 0.5rem;
	background-color: var(--bkMultipleChoice);
	border: 1px var(--brGeneral) solid;
	border-radius: 0.3rem;
}

.mcOption {
	display: flex;
	align-items: center;
}

.mcOption span.mcId {
	padding: 0.3rem 0.5rem;
	border: 1px var(--brGeneral) solid;
	border-radius: 0.3rem;
	min-width: 1.2rem;
	text-align: center;
	background: var(--bkMultipleChoiceNumber);
	color: var(--txMultipleChoice);
	user-select: none;
}

.mcOption span.mcTx {
	padding: 0.3rem 0.5rem;
	font-size: 1.1rem;
	line-height: 1.3rem;
	letter-spacing: .04rem;
	width: 70%;
	user-select: none;
	color: var(--txMultipleChoice);
}

.mcOption span.mcCk {
	width: 10%;
	text-align: center;
	font-size: 0;
	display: none;
}

.mcOption span.mcCk svg {
	width: 1.1rem;
}

.mcOption span.mcCk svg path {
	fill: var(--svgFill);
}

.gnt-choice-other {
	display: none;
}

.gnt-choice-other input {
	width: 90%;
	padding: 5% 3%;
	margin: 0.8rem 0.2rem 0.4rem;
	border: none;
	font-size: 1rem;
}


/*
div[show-button="off"] {
	display: none;
}

div[show-button="on"] {
	display: block;
}

ul {
	list-style: none;
}

ul li {
	margin: 0 0 .8rem;
}

.gnt-choice-section ul {
	padding: 0 !important;
}

.gnt-choice-list {
	list-style: none;
}

.gnt-choice-list li {
	margin: 0.7rem 0;
	cursor: pointer;
}

.gnt-choice-item {
	text-align: left;
	border: 1px solid;
	border-color: var(--choiceColor);
	background: var(--choiceBackground);
	padding: 0.5rem;
	display: flex;
	align-items: center;
	border-radius: 0.2rem;
	cursor: pointer;
	position: relative;
}

.gnt-choice-item svg {
	width: 1rem;
}

.chid {
	padding: 0.2rem 0.6rem;
	margin-right: 0.5rem;
	border-radius: 0.2rem;
	border: 1px solid;
	border-color: var(--choiceBorder);
	background: #f9f8fa6b;
	color: var(--choiceText);
	font-size: .9rem;
}

.chtx {
	width: 75%;
	position: relative;
	color: var(--choiceText);
	font-size: 1rem;
	padding-right: .7rem;
}

.chtx i {
	position: absolute;
	right: 0;
	padding: 0 0.2rem;
	display: none;
}

.chck {
	text-align :center;
	width: 15%;
}

.chck svg path {
	fill: var(--choiceText);
}

.gnt-choice-other {
	padding:.5rem 0;
	display:none;
}

.gnt-choice-other input {
	padding:.2rem .5rem;
	width:100%;
	margin:.5rem 0 0;
	font-size:1rem;
}

.swico {
	display: block !important;
}

@media screen and (min-width: 966px) {
	html {
		font-size: 20px;
	}

	.chtx {
		width: 90%;
		font-size: .9rem;
	}
}
*/