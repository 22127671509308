.card {
	border-color: var(--borderColor);
}

.card-check {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 0;
	right: 0;
	display: none;
}

.card-check-mask {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	right: 0;
	border-right: 0px solid transparent;
	border-left: 50px solid transparent;
	border-top: 50px solid var(--buttonBackground);
	border-bottom: 0px solid var(--buttonBackground);
}

.card-check svg {
	width: 50%;
	position: absolute;
	top: 0.4rem;
	left: 0.5rem;
	z-index: 99;
}

.gnt-views-documents-choice {
	text-align: center;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.gnt-views-documents-choice div.card {
	width: 43%;
	max-width: 207px;
	min-height: 8.5rem;
	margin: 0 .5rem 1rem;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	text-align: center;
}

.gnt-views-documents-choice div.card-image {
	background: #f1f1f1;
	height: 50%;
	display: flex;
	text-align: center;
	position: relative;
	padding: 10% 0;
}

.gnt-views-documents-choice div.card-image svg {
	width: 40%;
	max-width: 80%;
	max-height: 100%;
	margin: 0 auto;
}

.gnt-views-documents-choice div.card-image svg path {
	fill: var(--svgImageFill) !important;
}

.gnt-views-documents-choice div.card-body {
	height: 30%;
	padding: 0 0.8rem !important;
	display: flex;
	align-items: center;
	text-align: center;
}

.gnt-views-documents-choice h5.card-title {
	font-size: .7rem;
	margin: 0;
	display: block;
	width: 100%;
}

@media screen and (min-width: 966px) {
	.gnt-views-picture-choice {
		text-align: left;
	}

	.card {
		margin: 1rem 0.5rem;
		display: inline-flex !important;
	}

	.card-check-mask {
		border-left: 70px solid transparent;
		border-top: 60px solid var(--buttonBackground);
	}
}