.gnt-name-input {
    margin: 2rem 0 1rem;
}

.gnt-name-input input {
    padding: .2rem .5rem;
    width: 94%;
    height: 2rem;
    border: none;
    border-bottom: 1px #CCC solid;
    margin-bottom: 1rem;
    color: var(--colorText);
    font-size: 1.2rem;
}

.gnt-name-input input::placeholder {
    color: var(--colorPlaceholder);
}