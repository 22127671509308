.w5 li {
	width: 20%;
}

.w6 li {
	width: 16.6%;
}

.w7 li {
	width: 14.28%;
}

.w8 li {
	width: 12.5%;
}

.w9 li {
	width: 11.11%;
}

.w10 li {
	width: 10%;
}

.gnt-opscale-section {
	/* display: grid;
	grid-template-columns: 50% 50%; */
	display: block;
}

.gnt-forms-scale {
	list-style: none;
	margin: 1rem 0 .2rem;
	display: flex;
	width: 100%;
	text-align: center;
	cursor: pointer;
	padding: 0;
}

.gnt-forms-scale li {
	background: var(--bkMultipleChoice);
	align-items: center;
	font-size: 0.9rem;
	color: var(--colorText);
	cursor: pointer;
	padding: 0.6rem 0;
	display: block;
	border-radius: 0;
	border: 1px solid var(--brGeneral);
	margin: 0;
}

.gnt-forms-scale li.active {
	background: var(--bkButtonSuccess) !important;
	align-items: center;
	font-size: 0.9rem;
	color: var(--txButtonSuccess);
	cursor: pointer;
	padding: 0.6rem 0;
	display: block;
	border-radius: 0;
	border: 1px solid var(--choiceBorder);
	margin: 0;
}

.gnt-forms-scale li:hover {
	background: var(--buttonHover);
}

.gnt-forms-scale li span {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	border: 1px solid var(--choiceBorder);
	margin-right: 0.4rem;
	display: none;
}

.oslabel {
	display: flex !important;
	padding: 0.5rem 0;
}

.oslabel[data-flex-show="on"] {
	display: flex !important;
}

.oslabel-item {
	width: 100%;
	height: 33.3%;
	display: block;
	text-align: left;
}

.oslabel-item p {
	font-size: 0.7rem;
	width: 100%;
}

.items-left {
	align-items: flex-start;
	padding: 0 !important;
}

.items-center {
	text-align: center !important;
}

.items-bottom {
	align-items: flex-end;
	padding: 0 !important;
	text-align: right !important;
}

@media screen and (min-width: 966px) {
	html {
		font-size: 20px;
	}

	.gnt-views-table {
		width: 98%;
		left: 1%;
	}

	.gnt-views-content {
		max-width: 966px;
		margin-left: -483px;
		left: 50%;
	}

	.gnt-views-footer {
		text-align: right;
	}

	.gnt-opscale-section {
		display: block;
	}

	.gnt-forms-scale {
		display: flex;
		width: 100%;
	}

	.gnt-forms-scale li {
		padding: 0.6rem 0;
		display: block;
		border-radius: 0;
		border: 1px solid var(--choiceBorder);
		margin: 0;
	}
}