:root {
    --bkBody: #FFF;
    --bkFooter: #F1F1F1;
    --bkMultipleChoice: #F1F1F1;
    --bkMultipleChoiceNumber: #F1F1F1;
    --bkPictureChoice: #F1F1F1;
    --bkOpinionScale: #F1F1F1;
    --bkInput: #F1F1F1;
    --bkButtonSuccess: #80BB1A;
    --bkButtonSuccessHover: #80BB1A;
    --bkButtonInfo: #17A2B8;
    --bkButtonSuccessClick: #5A8410;
    --bkButtonInfoClick: #138697;
    --bkButtonSecondary: #F1F1F1;
    --txTitle: #888;
    --txTitleNumber: #80bb1a;
    --txMultipleChoice: #888;
    --txMultipleChoiceNumber: #888;
    --txDescription: #888;
    --txGeneral: #888;
    --txPlaceHolder: #C7C7C7;
    --txBrand: #c7c7c7;
    --txBrandCompany: #80BB1A;
    --txButtonSuccess: #FFF;
    --txButtonInfo: #FFF;
    --txButtonSuccessClick: #FFF;
    --txButtonInfoClick: #FFF;
    --brGeneral: #80BB1A;
    --brButtonSuccess: #80BB1A;
    --brButtonInfo: #17A2B8;
    --brButtonSuccessClick: #5A8410;
    --brButtonSuccessHover: #80BB1A;
    --brButtonInfoClick: #138697;
    --svgFill: #80bb1a;
}

input::placeholder {
    color: var(--txPlaceHolder) !important;
}


@font-face {
    font-family: "GT America Regular";
    src: url("../fonts/GTAmerica-Regular.woff");
    src: url("../fonts/GTAmerica-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    background: var(--bkBody);
}

html {
    font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
    color: var(--txGeneral);
    font-family: "GT America Regular";
    outline: none;
    box-sizing: inherit;
}

#app {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#wrapper {
    width: 100%;
    height: 93%;
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow: scroll;
}

.footer {
    height: 7%;
    display: flex;
    align-items: center;
    background: var(--bkFooter);
}

.footerNotes {
    width: 50%;
    padding: 0 1rem;
}

.footerBrand {
    width: 50%;
    padding: 0 1rem;
    text-align: right;
    font-size: .7rem;
    letter-spacing: .03rem;
    user-select: none;
    color: var(--txBrand);
}

.footerBrand span {
    color: var(--txBrandCompany);
}

.element {
    width: 100%;
    padding: 3rem 10%;
}

.errorQR {
    width: 80%;
    margin: 2rem auto 0;
    text-align: center;
}

#errorMessage {
    margin: 1rem 0 0;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    display: none;
    color: red;
    text-align: center;
}

#errorMessage p {
    color: red;
    font-size: .8rem;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

div[data-show=on] {
    display: block;
}

div[data-show=off] {
    display: none;
}

.screenLandscape {
    display: none;
    text-align: center;
}

.screenLandscape h2 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    width: 50%;
}

.screenLandscape svg {
    width: 8rem;
    margin: 0.5rem 0 0;
}

.screenLandscape svg path {
    fill: var(--bkButtonSuccess);
}

@keyframes rotation1 {
    25% {
        left: 0;
        transform: translateZ(-10vh);
    }

    50% {
        left: (20vh/1.5);
        transform: translateZ(0vh);
    }

    75% {
        left: 0;
        transform: translateZ(20vh);
        z-index: 2;
    }
}

@keyframes rotation2 {
    25% {
        right: 0;
        transform: translateZ(20vh);
        z-index: 2;
    }

    50% {
        right: (20vh/1.5);
        transform: translateZ(0vh);
    }

    75% {
        right: 0;
        transform: translateZ(-10vh);
    }
}

#mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .7;
    z-index: 999;
    display: none;
}

#popup {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    margin: -122px 0 0 -45%;
    background: var(--bkBody);
    padding: 5%;
    border: 1px #CCC solid;
    text-align: center;
    font-size: .9rem;
    line-height: 1.6rem;
    display: none;
    z-index: 9999;
}

#popup h2 {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
}

#popup p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.4rem;
}

.popup-item svg {
    font-size: 3rem;
}

.popup-item svg path {
    fill: var(--svgFill);
}

.popupButtons {
    display: flex;
    justify-content: space-around;
}

.popupButtonsItem button {
    min-width: 8rem;
    padding: 0.7rem 0.5rem;
    font-size: 1rem;
    letter-spacing: .9px;
    line-height: 1.2rem;
    border: 2px solid;
    -webkit-user-select: none;
    user-select: none;
    border: none;
    color: var(--txButtonInfo);
}

.btnEnd {
    background-color: var(--bkButtonInfo);
}

.btnContinue {
    background-color: var(--bkButtonSuccess);
}

.loader {
    position: fixed;
    background: #e9ecef;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    display: none;
    align-items: center;
    text-align: center;
}

.loader p {
    width: 100%;
    color: #FFF;
}

.loaderICON {
    position: absolute;
    width: 1vh;
    height: 1vh;
    top: 50%;
    left: 50%;
    margin-top: -3vh;
    margin-left: 0vh;
    perspective: 60vh
}

.loaderICON:before,
.loaderICON:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loaderICON:before {
    left: -1.33vh;
    background: linear-gradient(135deg, var(--svgFill) 46%, var(--svgFill) 100%);
    transform: translateZ(0vh);
    z-index: 1;
    animation: rotation1 1.5s ease-out infinite;
}

.loaderICON:after {
    right: -1.33vh;
    background: linear-gradient(135deg, rgb(175 174 171) 46%, rgb(131 132 125) 100%);
    transform: translateZ(0vh);
    z-index: 1;
    animation: rotation2 1.5s ease-out infinite;
}

.vw-video {
    width: 50%;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #ccc;
    font-size: 0;
}

.vw-video video {
    width: 267px;
    height: 200px;
    margin: 0;
}

.vw-video canvas {
    width: 267px;
    height: 200px;
    margin: 0;
}

.vw-timer {
    display: block;
    border-radius: .25rem;
}

#fTimer {
    background-color: var(--bkButtonSuccess);
    color: var(--txButtonSuccess);
    display: none;
    width: 3rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    text-align: center;
}

@media (max-height: 505px) and (orientation: landscape) {
    #wrapper {
        display: none;
    }

    .footer {
        display: none;
    }

    .screenLandscape {
        overflow: hidden;
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        flex-direction: column;
        color: gray;
        background-color: white;
    }
}

@media screen and (min-width: 560px) {
    .element {
        width: 80%;
    }
}

@media screen and (min-width: 560px) and (orientation:landscape) {
    .element {
        width: 100%;
    }
}